import Category from '../../api/Category';

const category = {
  namespaced: true,
  state: {
    categories: [],
    category: {},
  },
  actions: {
    getCategory({ commit }) {
      Category.all()
        .then(response => {
          commit('setCategory', response.data.data);
        });
    },
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    setCategory(state, categories) {
      state.categories = categories;
    },

  },
  getters: {},
};

export default category;
