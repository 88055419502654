import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router';
import store from './store';
import './axiosinstance';
import 'element-plus/dist/index.css';
import './assets/css/all.min.css';
import './assets/css/style.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import titleMixin from './mixins/title';
// eslint-disable-next-line import/order
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const axios = require('axios');






const app = createApp(App);
app.mixin(titleMixin);
// app.mixin(titleMixin)
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store)
  .use(router)
  .mount('#app');
