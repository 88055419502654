import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Master.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/Product'),
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/shoping_cart/ShopingCart'),
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/shoping_cart/Checkout'),
      },
      {
        path: '/thank/you/message',
        name: 'Thank you message',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ThankYouMessageWithInvoice'),
      },
      {
        path: '/product/:slug',
        name: 'Product Details',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/SingleProduct'),
      },
      {
        path: '/category/:slug',
        name: 'Category Product',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/Product'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Login'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Registration'),
      },
      {
        path: '/forget/password',
        name: 'Forget Password',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/ForgetPassword'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Dashboard'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {

    // console.log('record', record.meta.auth)
    if (store.state.auth.user) {
      if (!store.state.auth.loggedIn) {
        next({
          name: 'login'
        });
      } else {
        next();
      }
    } else {
      next({
        name: 'login'
      });
    }
  } else {
    next();
  }
});

export default router;
