import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import shopingCart from './modules/shopingCart';
import products from './modules/products';
import category from './modules/category';
import auth from './modules/auth.module';
import setting from './modules/setting.module';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    shopingCart,
    products,
    category,
    auth,
    setting,
  },
  plugins: [createPersistedState()],
});
