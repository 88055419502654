<template>
  <keep-alive :max="50" include="home">
    <router-view :key="$route.fullPath"/>
  </keep-alive>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  methods: {
    ...mapActions('setting', ['getSettings']),
    fetchData() {
      this.getSettings(); // Dispatch the action when the component is created/mounted
    }
  },
  created() {
    this.fetchData(); // Call the method to fetch data when the component is created
  }
};
</script>
