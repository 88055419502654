const shopingCart = {
  namespaced: true,
  state: {
    cart: [],
  },
  actions: {
    // old code
    // addProductToCart(context, product) {
    //   console.log('product',product);
    //   // eslint-disable-next-line no-undef
    //   const cartItem = context.state.cart.find((item) => item.id === product.id);
    //   if (!cartItem) {
    //     context.commit('pushProductToCart', product);
    //   } else {
    //     context.commit('incrementItemQuantity', cartItem);
    //   }
    //   context.commit('decrementProductQuantity', product);
    // },
    addProductToCart(context, product) {
      // Check if there's already an item with the same id, size, and color in the cart
      const cartItem = context.state.cart.find(item => (
        item.id === product.id &&
        item.color_id === product.color_id &&
        item.attribute_value_id === product.attribute_value_id
      ));

      if (!cartItem) {
        // If no matching item found, add the product as a new item
        context.commit('pushProductToCart', product);
      } else {
        // If a matching item is found, increment its quantity
        context.commit('incrementItemQuantity', cartItem);
      }

      // Decrement the product quantity regardless of whether it's added or incremented
      context.commit('decrementProductQuantity', product);
    },
    removeCartItem({
      state,
      commit,
      rootState
    }, cartItem) {
      commit('removeItemFromCart', { itemId: cartItem.id });
    },
    emptyCart({
      commit,
    }) {
      commit('emptyCart');
    },
  },
  mutations: {
    pushProductToCart(state, product) {
      state.cart.push({
        id: product.id,
        color_id: product.color_id ? product.color_id : '',
        attribute_id: '',
        attribute_value_id: product.attribute_value_id ? product.attribute_value_id : '',
        quantity: 1,
        details: product,
      });
    },
    incrementItemQuantity(state, cartItem) {
      cartItem.quantity++;
    },
    decrementProductQuantity(state, product) {
      product.quantity--;
    },
    removeItemFromCart(state, { itemId }) {
      const cartItem = state.cart.find(item => item.id === itemId);
      if (cartItem) {
        state.cart.splice(state.cart.indexOf(cartItem), 1);
      }
    },
    emptyCart(state) {
      state.cart = [];
    },
  },
  getters: {
    cartProducts(state) {
      return state.cart;
    },
    cartTotal(state, getters) {
      return getters.cartProducts.reduce((total, product) => {
        const price = product.details.price;
        const sum = (parseFloat(price) * parseFloat(product.details.discount)) / 100;
        const total_price = parseFloat(price) - sum;
        return total + total_price * product.quantity
      }, 0);
    },
  },
};

export default shopingCart;
