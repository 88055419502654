import Api from './Api';

export default {
  all() {
    return Api.get('products');
  },
  show(slug) {
    return Api.get(`products/${slug}`);
  },
};
