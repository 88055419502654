import Setting from '../../api/Setting';

const setting = {
  namespaced: true,
  state: {
    setting: {},
  },
  actions: {
    getSettings({ commit }) {
        Setting.all()
        .then(response => {
         
          commit('setSetting', response.data.data);
        });
    },
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    setSetting(state, setting) {
      state.setting = setting;
    },

  },
  getters: {
    get_setting: (state) => state.setting,
  },
};

export default setting;
