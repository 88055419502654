import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const auth = {
  namespaced: true,
  state: {
    user: {},
    loggedIn: false,
    usrPerms: []

  },
  actions: {
    login({
      dispatch,
      commit
    }, form) {
      commit('loginRequest', form);
      var url = `${process.env.VUE_APP_API_URL}api/login`;
      return axios.post(url, form)
        .then((res) => {
          commit('loginSuccess', res.data.user);
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    logout({ commit }) {
      localStorage.removeItem('api_token');
      var url = '/api/v1/user/logout';
      return axios.get(url)
        .then(res => {
          localStorage.removeItem('api_token');
          commit('logout');
          return res;
        })
        .catch((err) => {
          return err;
        });

    },

  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.loggedIn = false;
      state.user = null;
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.usrPerms = permissions;
    }
  },
  getters: {
    authUser: (state) => state.user,
    loggedIn: (state) => state.loggedIn,
  }
};

export default auth;


