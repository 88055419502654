import Product from '../../api/Product';

const products = {
  namespaced: true,
  state: {
    products: [],
    product: {},
  },
  actions: {
    getProducts({ commit }, payload) {

      Product.all()
        .then(response => {
          commit('setProducts', response.data.data);
        });
    },
    getProductDetails({ commit }, slug) {
      Product.show(slug)
        .then(response => {
          commit('setProduct', response.data.data);
        });
    },
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    setProducts(state, products) {
      state.products = products;
    },
    setProduct(state, product) {
      state.product = product;
    },

  },
  getters: {},
};

export default products;
