import axios from 'axios';
import store from './store';

axios.defaults.baseURL = process.env.VUE_APP_URL;

axios.interceptors.request.use(async (config) => {
  if (store.state.auth.loggedIn) {
    config.params = {
      ...config.params,
      // api_token: store.state.auth.user.api_token
    };
  }
  return config;
}, (err) => Promise.reject(err));
//
axios.interceptors.response.use((response) => {
  if (response.data.status == 401 || response.data.status_code == 401) {
    if (response.data.data.status_code == 401) {
      localStorage.removeItem('api_token');
      window.location = '/login';
    }
    window.location = '/login';
  }
  return response;
});
